@font-face {
font-family: 'SFPro-Bold';
src: url('../fonts/SFPro/SFPRODISPLAYBOLD.OTF') format('opentype');
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: 'SFPro-HeavyItalic';
src: url('../fonts/SFPro/SFPRODISPLAYHEAVYITALIC.OTF') format('opentype');
}

@font-face {
font-family: 'SFPro-LightItalic';
src: url('../fonts/SFPro/SFPRODISPLAYLIGHTITALIC.OTF') format('opentype');
}

@font-face {
font-family: 'SFPro-Medium';
src: url('../fonts/SFPro/SFPRODISPLAYMEDIUM.OTF') format('opentype');
}

@font-face {
font-family: 'SFPro-Regular';
src: url('../fonts/SFPro/SFPRODISPLAYREGULAR.OTF') format('opentype');
}

@font-face {
font-family: 'SFPro-SemiboldItalic';
src: url('../fonts/SFPro/SFPRODISPLAYSEMIBOLDITALIC.OTF') format('opentype');
}

@font-face {
font-family: 'SFPro-ThinItalic';
src: url('../fonts/SFPro/SFPRODISPLAYTHINITALIC.OTF') format('opentype');
}

@font-face {
font-family: 'SFPro-UltraLightItalic';
src: url('../fonts/SFPro/SFPRODISPLAYULTRALIGHTITALIC.OTF') format('opentype');
}

@font-face {
font-family: 'SFPro-BlackItalic';
src: url('../fonts/SFPro/SFPRODISPLAYBLACKITALIC.OTF') format('opentype');
}
/* 4 */

@font-face {
font-family: 'Geist-Black';
src: url('../fonts/Geist/Geist-Black.ttf') format('opentype');
}

@font-face {
font-family: 'Geist-Bold';
src: url('../fonts/Geist/Geist-Bold.ttf') format('opentype');
}

@font-face {
font-family: 'Geist-ExtraBold';
src: url('../fonts/Geist/Geist-ExtraBold.ttf') format('opentype');
}

@font-face {
font-family: 'Geist-ExtraLight';
src: url('../fonts/Geist/Geist-ExtraLight.ttf') format('opentype');
}

@font-face {
font-family: 'Geist-Light';
src: url('../fonts/Geist/Geist-Light.ttf') format('opentype');
}

@font-face {
font-family: 'Geist-Medium';
src: url('../fonts/Geist/Geist-Medium.ttf') format('opentype');
}

@font-face {
font-family: 'Geist-Regular';
src: url('../fonts/Geist/Geist-Regular.ttf') format('opentype');
}

@font-face {
font-family: 'Geist-Semibold';
src: url('../fonts/Geist/Geist-SemiBold.ttf') format('opentype');
}

@font-face {
font-family: 'Geist-Thin';
src: url('../fonts/Geist/Geist-Thin.ttf') format('opentype');
}

@font-face {
font-family: 'GeistMono-Black';
src: url('../fonts/GeistMono/GeistMono-Black.ttf') format('opentype');
}

@font-face {
font-family: 'GeistMono-Bold';
src: url('../fonts/GeistMono/GeistMono-Bold.ttf') format('opentype');
}

@font-face {
font-family: 'GeistMono-Light';
src: url('../fonts/GeistMono/GeistMono-Light.ttf') format('opentype');
}

@font-face {
font-family: 'GeistMono-Medium';
src: url('../fonts/GeistMono/GeistMono-Medium.ttf') format('opentype');
}

@font-face {
font-family: 'GeistMono-Regular';
src: url('../fonts/GeistMono/GeistMono-Regular.ttf') format('opentype');
}

@font-face {
font-family: 'GeistMono-SemiBold';
src: url('../fonts/GeistMono/GeistMono-SemiBold.ttf') format('opentype');
}

@font-face {
font-family: 'GeistMono-Thin';
src: url('../fonts/GeistMono/GeistMono-Thin.ttf') format('opentype');
}

@font-face {
font-family: 'GeistMono-UltraBlack';
src: url('../fonts/GeistMono/GeistMono-UltraBlack.ttf') format('opentype');
}

@font-face {
font-family: 'GeistMono-UltraThin';
src: url('../fonts/GeistMono/GeistMono-UltraLight.ttf') format('opentype');
}